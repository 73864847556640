import { createRouter, createWebHistory } from 'vue-router';

import { landingRoutes } from '@/router/ru/landingRoutes';
import { privicyPolicyRoutes } from '@/router/ru/privicyPolicy';
import { companyDetailsRoutes } from '@/router/ru/companyDetailsRoutes';
import { paymentRoutes } from '@/router/ru/payment';
import { emptyWidgetRoutes, widgetRoutes } from '@/router/ru/widgetRoutes';
import { errorRoutes } from '@/router/ru/errorRoutes';

const routes = [...landingRoutes, ...privicyPolicyRoutes, ...companyDetailsRoutes, ...paymentRoutes, ...widgetRoutes, ...emptyWidgetRoutes, ...errorRoutes];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

export { router, routes };
